.support-mail {
  width: 50px;
  height: 50px;
  padding-top: 18px;
  border: solid 2px #ff540a;
  border-radius: 25px;
  margin: 0 auto;
  color: #ff540a;
  font-size: 10px;
}

.help-desk a{
  font-size: 20px;
  line-height: 25px;
  color: #002856;
  text-decoration: underline;
}

.submit-ticket {
  font-size: 14px;
  line-height: 19px;
  color: #616161;
}

.no-data .gridcell{
  display: block;
}

.not-enough-data-icon{
  font-size: 49px;
  color: #ff540a;
}

.not-enough-data-center {
  height: 100%;
  margin: auto;
  width: 50%;
  margin-top:8px;
}