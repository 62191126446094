.gender-diversity{
    padding:19px 15px 0px 15px;
}
.gender-count-graph{
    text-align: center;
    padding: 0px 28px;
    width:200px;
    margin:0 auto;
}
.gender-percent{
    font-size: 24px;
    font-weight: 600;
    line-height: 1;
    color: #002856;
}
.gender-icons{
    margin-top:10px;
    margin-bottom:13px;
}
.gender-message{
    font-size: 14px;
    line-height: 1.14;
}
.gender-count{
    font-size: 14px;
    font-weight: 500;
    line-height: 1.14;
    color: #002856;
    margin-top:5px;
}
.gender-icon{
    width: 13px !important;
    height: 27px;
    font-size: 26px;
    line-height: 26px;
    display:inline-block;
    color: #e0e0e0;
}
.highlight{
    color:#002856;
}
.gender-graph{
    margin-left:-15px;
}