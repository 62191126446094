.fade-enter .multirole-title{
    height: 0px !important;
}

.fade-enter.fade-enter-active .multirole-title{
    height: 36px !important;
    transition:height 0.3s linear;
}

.fade-exit .multirole-title:first-of-type::after{
    position: absolute;
  content: '';
  top:0;
  left: 0;
  width: 0;
  height: 100%;
  background-color:#d3d3d3;
  transform-origin:left;
  transition:width 0.3s ease, height 0.2s ease 0.2s;
  animation-direction: reverse;
}

.fade-exit-active .multirole-title:first-of-type::after{
    width: 180%;
    color: #d3d3d3;
    height: 0%;
  }

  .fade-exit .multirole-title{
    color: #d3d3d3;
  }

  .popup-exit{
    opacity : 1;
    transition:opacity 1.5s;
  }

  .popup-exit-active{
    opacity : 0;
  }

  .export-button{
    margin-top: 15px;
  }