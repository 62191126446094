.popover{
    width: 316px;
    background: #ffffff;
    position: absolute;
    z-index: 1;
    top: 134px;
    left:5px;
    box-shadow: 0 1px 3px 0 rgba(97, 97, 97, 0.28);
    border: 2px solid #d3d3d3;
}

.sticky-true.popover{
    top: 46px;
    right: 74px;
}
.btn-cancel{
    margin-right: 5px;
}
.pull-right{
    display: flex;
}
.popover-body{
    padding: 20px 18px 20px 18px;
}

.popover-label{
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    color: #002856;
}

.popover-label i,
.popover-limit-char {
    font-weight: 400;
}
.popover-limit{
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    text-align: right;
    color: #616161;
}

input {
    width: 100%;
}

.textarea{
    width: 276px !important;
    box-shadow: none;
    height: 70px;
}

textarea {
    border: solid 2px #d3d3d3;
    background-color: #fff;
    padding: 5px 15px;
    font-size: 14px;
}

textarea:active, textarea:focus {
    border: solid 2px #0052d6;
    outline: 0;
}

.clear{
    clear: both;
}

.popover-arrow:after, .popover-arrow:before {
	bottom: 100%;
	left: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.popover-arrow:after {
	border-bottom-color: #ffffff;
	border-width: 7px;
	margin-left: 14px;
}
.popover-arrow:before {
	border-bottom-color: #d3d3d3;
	border-width: 10px;
	margin-left: 11px;
}
#reportName{
    margin-top:12px;
}
#reportDesc{
    margin-top:9px;
}