.expandingContainer{
    margin-top : 20px;
}
.expandingContainer .expandBtn{
    border-radius: 100px;
    border: 2px solid #002856;
    line-height: 2.3;
    padding-right: 15px;
    padding-left: 15px;
    display: inline-block;
    height: 36px;
    background-color: #fff;
    cursor: pointer;
}
.expandingContainer .expandBtnBlock{
    text-align: center;
}
.expandingContainer .expandBtn:hover{
    background-color: #002856;
}
.expandingContainer .expandBtnText{
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #002856;
    margin-right: 8px;
}
.expandingContainer .expandBtn:hover .expandBtnText,.expandingContainer .expandBtn:hover .ga{
    color: #ffffff;
}
.expandingContainer .ga{
    position: relative;
    font-size: 20px;
    color: #002856;
}
.expandingContainer .ga-top{
    top: 6px;
}
.expandingContainer .ga-bottom{
    top: 3px;
}
.expandingContainer .expandBtn.active{
    background-color: #002856;
    margin-top : 20px;
}
.expandingContainer .expandBtn.active span{
    color : #ffffff;
}
.expandingContainer .expandBtn.active:hover{
    background-color: #011c3c;
}
.expandingContainer .slidePanel{
    max-height: 0;
    transition: max-height 0.5s ease-out;
    overflow: hidden;
}
.expandingContainer .slidePanel.open{
    max-height: 350px;
    transition: max-height 0.5s ease-in;
}