.guageDataContainer{
    text-align: center;
}
.guagePercent{
    font-size:24px;
    color:#002856;
    font-weight:500;
    display:block;
}
.guageNumber{
    font-size:14px;
    color:#4a4a4a;
    display:block;margin-top:5px;
    font-weight: normal;
}