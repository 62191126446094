.main-nav{
    border-bottom: 1px solid #e0e0e0;
    min-height: 55px;
}

.CRHeader{
    margin-top: 12.5px;
    margin-bottom: 12.5px;
}

.content-body{
    height: 553px;
    margin-top: 6px;
}

.ga-add01, .ga-check01{
    font-size: 17px;
    margin-right: 10px;
}

.ga-add01{
    color: #002856;
}

.ga-check01{
    color: #00a76d;
}

.pointer{
    cursor: pointer;
}

.selected-role-circle{
    width: 36px;
    height: 36px;
    border-radius: 50%;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    background-color: #002856;
    display: inline-block;
    font-size: 10px;
    vertical-align: middle;
    padding-top: 8px;
}

.selected-role-heading{
    font-size: 15px;
    font-weight: 500;
    color: #002856;
}

.role-box{
    box-shadow: -7px 0 9px -2px rgba(0, 0, 0, 0.3);
    border-left: 1px #e0e0e0;
    background-color: white;
    margin-top: -16px;
    padding-top: 10px;
    height: 568px;
    max-width: 527px;
    transition: width 0.4s linear 0s;
    float: right;
    opacity: 1;
    position: absolute;
    right: 0;
    padding-left: 10px;
    padding-right: 10px;
}

.role-box-op{
    width: 0px !important;
    opacity: 0 !important;
    position: absolute;
    display: none;
}

.selected-items{
    margin-top: 10px;
    max-height: 450px;
    overflow-y: auto;
    /* box-shadow: inset 0px -7px 8px -2px rgba(0, 0, 0, 0.1); */
    overflow-x: hidden;
    cursor: pointer;
}

body{
    overflow-x: hidden;
}

.content-body .parent-record:first-of-type .gridcell {
    border-top: none;
}
  
.content-body .parent-record:nth-of-type(2) .gridcell {
    border-top: 1px solid #e0e0e0;
}

.flyoutmenuprimary{
    z-index: 9999 !important;
}

.content-body thead{
    border-bottom: 1px solid #002856;
}

.content-body tbody{
    height: 499px;
    overflow-y: auto;
    display: block;
}

.content-body tbody tr{
    display: block;
}

.content-body .Dropdown-root .label-width{
    width: 89%;
    text-align: left;
}

.content-body .Dropdown-menu{
    min-width: 298px;
}

.content-body .Dropdown-control{
    padding-right: 49px;
}

.content-body .Dropdown-root .ga-checkbox {
    display: inline-block;
}

.role-box.hideop{
    width : 0%;
    opacity: 0;
}

.width0{
    width:0px;
}

.opacity0{
    opacity: 0 !important;
}

.grid-box{
    transition: width 0.4s linear 0s;
}

::-webkit-scrollbar{
    width:5px !important
}

::-webkit-scrollbar-thumb
{
    background:#6f7878 !important;
    border-radius:2.5px !important
}

.multirole-title{
    font-size: 13px;
    color: #616161;
    height: 36px;
    line-height: 16px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

@media (min-width: 1030px) and (max-width: 1430px){
    body{
        overflow-x: hidden;
    }
}

.multirole-title .ga{
    vertical-align: middle;
    font-size: 17px;
    display: inline-block;
}

.multirole-title .underline{
    text-decoration: underline;
    display: inline-block;
    width: 80%;
    vertical-align: middle;
}
.underline
{
    text-decoration: underline;
}

.multirole-row {
    height: 36px;
    max-height: 36px;
}

.ga-close01{
    color: #002856;
}
  
  /**
   * Tooltip Styles
   */
  
  /* Base styles for the element that has a tooltip */
  [data-tooltip],
  .tooltip {
    position: relative;
    cursor: pointer;
  }
  
  /* Base styles for the entire tooltip */
  [data-tooltip]:before,
  [data-tooltip]:after,
  .tooltip:before,
  .tooltip:after {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
  }
  
  /* Show the entire tooltip on hover and focus */
  [data-tooltip]:hover:before,
  [data-tooltip]:hover:after,
  [data-tooltip]:focus:before,
  [data-tooltip]:focus:after,
  .tooltip:hover:before,
  .tooltip:hover:after,
  .tooltip:focus:before,
  .tooltip:focus:after {
    visibility: visible;
    opacity: 1;
  }
  
  
  /* Base styles for the tooltip's content area */
  .tooltip:after,
  [data-tooltip]:after {
    content: attr(data-tooltip);
    min-height: 22px;
    font-size: 12px!important;
    line-height: 15px!important;
    color: #fff!important;
    background-color: rgba(97,97,97,.9)!important;
    border: none!important;
    padding: 3px 5px!important;
    border-radius: 0;
    font-weight: 400!important;
    font-family: Graphik Web,Arial!important;
    z-index: 9999!important;
    text-align: left!important;
    position: fixed;
  }

 thead .publish {
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    padding-left: 30px;
    padding-top: 10px;
  }

.publish i{
    margin-left: 5px;
  }




#advHeader .nav-right-section{
    float: right;
    margin-top: 8px;
} 

#advHeader .nav-left-section{
    display:inline-block
    }


.table-responsive thead, .table-responsive tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}
.content-body .modal-header {
    padding: 0 0 10px !important;
    margin: 0;
    border-bottom: none;
}
.content-body span.filename{
    padding-left: 10px;
}

.nav-right-section input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    cursor: pointer;
    display: block;
    visibility: hidden;
}
.content-body .button-row{
    gap: 4px
}
.content-body .modal-title {
    text-align: left;
    margin-bottom: 10px;
    font-weight: 500;
}
.content-body .heading3 {
    color: #002856;
    font-size: 18px;
    line-height: 1.28;
    letter-spacing: normal;
    margin-bottom: 10px;
    font-weight: bold;
}
.content-body .heading4 {
    color: #002856;
    font-size: 16px;
    line-height: 1.28;
    letter-spacing: normal;
    margin-bottom: 20px;
}

.table-responsive  tbody tr .render-city-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.content-body .parent-record .delete-btn {
    display: none;
}
.content-body .parent-record:hover .delete-btn {
    display: inline-block;
    height: 30px;
    padding: 0px 15px;
}
.sticky-container .prompt-body {
    text-align: left;
    margin: 15px 0px 20px 0px;
}
.sticky-container .desclaimer-modal .prompt-body {
    margin: 20px 0px 20px 0px;
    color: #212121;
}
.sticky-container .desclaimer-modal .button-row {
    text-align: right;
    margin-top: 75px;
}
.sticky-container .button-row {
    text-align: right;
    margin-top: 15px;
}
.sticky-container .button-row .btn {
    margin-left: 10px !important;
    padding: 6.5px 20px;
}

.sticky-container .button-row .MuiButton-root {
    margin-left: 10px !important;
}

.custom-role-footer{
    margin-top: 25px;
}

.custom-role-footer .tn-footer-wrapper{
    width: 90%;
    min-width: 1030px;
    max-width: 1430px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
