.view-intake-information{
    width: 185px;
    height: 19px;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.36;
    letter-spacing: normal;
    margin-bottom: 14px;
    color: #0052d6;
    cursor: pointer;
}