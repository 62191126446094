.flyoutsection .bm-item{
    outline:none;
}
.roles-flyout .tn-heading3 {
    margin-top: 0px;
    margin-bottom: 4px;
}

.jobrole-location{
    color: #616161;
    font-style: italic;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    margin-bottom: 11px;
}

.skills-analyzed-container{
    background-color: #002856;
    padding: 5px 10px 10px;
    color: #ffffff;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 16px;
    margin-right: 15px;
}

.skills-analyzed-header{
    font-weight: 500;
}

.jobrole-body{
    font-size: 14px;
    line-height: 19px;
    padding-right: 25px;
}

ul {
    margin-bottom: 0px;
    padding-inline-start: 17px;
}

.flyoutsection .bm-menu-wrap .bm-cross-button{
    left: 5px !important;
}

.bm-cross-button button{
	z-index: -999
}

.bm-cross-button .ga-close01{
	z-index: 1200
}

.content-body .gridcell-edits.col-md-6 span:not(.ga){
    width: 85%;
}
.roles-flyout .btn-view-results{
    padding: 0;
}
.btn-view-results{
    float:right;
    position: absolute;
    top:15px;
    right: 40px;
}
.roles-flyout .btn-view-results a{
    padding: 0;
    padding: 10px 15px;
    line-height: 14px;
}
.btn-view-results a{
    color: #fff;
    text-decoration: none !important;
}