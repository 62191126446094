.keystat-container {
    height: 130px;
    background-color: #f0f0f0;
    padding: 15px;
    position: relative; }
  
  .keystat-name {
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    color: #002856; }
  
  .keystat-value {
    font-size: 24px;
    font-weight: 600;
    line-height: 23px;
    color: #002856;
    position: absolute;
    bottom: 15px; }
    