.popper-content {
  color: #000000;
}

input:active,
input:focus {
  border: none !important;
}

input#reportName:active,
input#reportName:focus {
  border: 2px solid #0052d6 !important;
}

header.navbar .header-wrapper {
  display: flex;
  align-items: center;
  padding: 12px 20px 12px 60px;
  border-bottom: 1px solid #e0e0e0;
  width: 100%;
  background: #fff;
  height: 60px;
}

header.navbar .header-wrapper .tn-announcement-banner {
  position: fixed;
  top: 0;
  left: 0;
}

header.navbar .header-wrapper .header-content {
  padding-left: 20px;
  height: 22px;
}

header.navbar .header-wrapper .tn-universal-search-container {
  margin: 0 auto;
}

header.navbar .header-wrapper .tn-universal-search-container .MuiFormControl-root {
  border-radius: 9px;
  outline: 2px solid #C7CAE0;
  margin-top: 0;
  height: 36px;
}

.tn-universal-search-container .MuiFormControl-root .MuiInputBase-root {
  padding: 8px 60px 8px 20px;
  height: 36px;
}

.tn-universal-search-container .MuiFormControl-root .MuiInputBase-root .MuiInputAdornment-root {
  z-index: 0;
  margin: 0;
  position: absolute;
  right: 20px;
}

.tn-universal-search-popper {
  margin-left: 15px !important;
  margin-top: -6px !important;
  background-color: #ffff;
}

@media (max-width: 1240px) {
  header.navbar .header-wrapper .tn-universal-search-container {
    width: 575px !important;
  }

  .tn-universal-search-popper .popper-content {
    width: 575px !important;
  }
}

@media (min-width: 1240px) {
  header.navbar .header-wrapper .tn-universal-search-container {
    width: 661px !important;
  }

  .tn-universal-search-popper .popper-content {
    width: 661px !important;
  }
}

.tn-universal-search-container  input::-ms-input-placeholder {
  opacity: 1; 
}

.tn-universal-search-container .MuiInputBase-input,
.tn-universal-search-container  input::placeholder {
  opacity: 1;
}

.tn-universal-search-container  input:focus::-ms-input-placeholder {
  color: transparent !important;
  opacity: 0;
}

.tn-universal-search-container  input:focus::placeholder {
  color: transparent !important;
  opacity: 0;
}

.tn-universal-search-container .MuiInputBase-root .MuiInputAdornment-root .tn-icon.icon-magnifying-glass-search {
  font-size: 20px;
}