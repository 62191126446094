
#salary-section {
    margin-bottom: 90px !important;
}
#salary-section .RightSalaryToolTip{
    margin-left: -25px;
}
#salary-section .LeftSalaryToolTip{
    margin-left: -50px;
}
#salary-section .salary-number{
    font-size: 14px;;
}

#salary-section .RightSalaryToolTip:after{
    left: 24px;
}
#salary-section .LeftSalaryToolTip:after{
    left: 48px;
}

#salary-section .market_arrow_box:after {
    top: -20px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

#salary-section .market_arrow_box:after {
    border-color: rgba(136, 183, 213, 0);
    border-top-color: #009ad7;
    border-width: 10px;
    transform: rotate(180deg)
}

#salary-section .market_arrow_box{
    position: absolute;
    min-width: 94px;
    height: 50px;
    border-radius: 1px;
    background-color: #009ad7;
    margin-top:30px;
    color: #FFFFFF;
    padding:5px;
    text-align: center;
}
#salary-section .market_arrow_box .label{
   font-weight: 500;
}
