.root-loading{
	width: fit-content;
  height: fit-content;
  margin: calc((80vh / 2)) auto;
}

body #root {
  padding-right: 5px;
}

.main-container.footer-edits.margin-top-135{
  margin-top: 30px;
}

.tnp-nav-logo-text-product:hover{
  text-decoration: none;
}

/* Drop inactive hover state */
.Dropdown-root:hover .Dropdown-control {
background-color: #f0f0f0;
  border: solid 2px #d3d3d3;
color: #616161;
}

.Dropdown-root:hover .Dropdown-arrow, .Dropdown-root:hover .Dropdown-placeholder,
.Dropdown-root.is-open:hover .Dropdown-arrow, .Dropdown-root.is-open:hover .Dropdown-placeholder {
color: #616161;
}

/* Drop inactive menu state */
.Dropdown-root.is-open:hover .Dropdown-control {
background-color: #ffffff;
color: #616161;
}


/* Drop active hover state */
.Dropdown-control.dropdown-changed{
  background-color: #efedfa;
  font-weight: 500;
  color: #0052d6 !important;
  border: solid 2px #0052d6;
}

.Dropdown-root:hover .Dropdown-control.dropdown-changed {
  background-color:#ccdcf7;
  border: solid 2px #0052d6;
}

.Dropdown-root:hover .Dropdown-control.dropdown-changed .Dropdown-placeholder{
  color: #0146b5;
}

.Dropdown-control.dropdown-changed:hover .Dropdown-arrow:before{
  color: #0146b5;
}

thead .colHead-edits{
display: flex;
align-items: flex-end;
justify-content: flex-end;
margin-bottom: 5px !important;
padding-right: 3px;
}

thead .colHead-edits input{
width: 97%;
}

thead .colHead-edits .ga-sort{
margin-bottom: 10px;
}

thead .colHead-edits .ga-caret-up{
margin-bottom: 15px;
}

thead .colHead-edits .ga-caret-down{
margin-bottom: 5px;
}

tbody .gridcell-edits.col-md-3 {
padding-left: 37px;
}

tbody .gridcell-edits.col-md-3.primary {
padding-left: 20px;
}

@media (min-width: 1030px) and (max-width: 1200px){
thead .colHead-edits input{
  width: 93%;
}
}
.left{
float: left;
}

.grid-box .colHead-edits .Dropdown-root{
width:86%
}
thead .colHead-edits input{
width: 95%;
}
.col-md-12.grid-box thead .colHead-edits.primary input{
width: 91%;
}
.col-md-8.grid-box thead .colHead-edits.primary.col-md-3 input{
width: 86%;
}

header.navbar {
z-index: 16 !important;
}

.client-switch-modal .button-row .btn{
margin-left: 10px;
}

.client-switch-modal .button-row .btn.btn-disabled{
padding: 8.5px 20px;
border-left: 2px solid #d3d3d3;
border-right: 2px solid #d3d3d3;
}

.client-switch-modal .button-row .btn-disabled.btn:active{
box-shadow: 0 0 0;
}

.diversity-widget .widget-item-upper{
padding-bottom:0px;
border: none;
}

.diversity-widget .twin-widget-wrapper{
border: none;
}

.navbar-brand .tnp-nav-logo-text{
cursor: pointer;
margin-left: 0 !important;
}
