.pdf-header {
    height: 51px;
    background-color: #002856;
    color: #ffffff;
    font-size: 12px;
    width: 100%; }
    .pdf-header .pull-left {
      margin-left: 40px;
      margin-top: 20px; }
    .pdf-header .pull-right {
      margin-right: 40px;
      margin-top: 20px; }
  
  .report {
    font-family: Graphik Web, Arial !important;
    font-size: 10px;
    background-color: #ffffff;
    width: 822px; }
    .report .main-container {
      width: auto !important;
      margin: 15px 40px !important;
      min-width: auto !important;
      max-width: none !important;
      padding: 0 !important; }
    .report .page-height {
      height: 877px; }
    .report .coverpage-height {
      height: 660px; }
    .report .continued {
      font-size: 10px;
      color: #0052d6;
      float: right;
      margin-right: 40px; }
    .report .footer-continue-section {
      height: 35px; }
    .report .title-container {
      background-color: #002856;
      height: 260px;
      padding-left: 40px;
      padding-right: 40px; }
      .report .title-container .report-appname {
        font-size: 12px;
        color: #ffffff;
        height: 90px;
        padding-top: 40px; }
      .report .title-container .report-title {
        font-size: 42px;
        font-weight: bold;
        color: #ffffff;
        height: 100px;
        line-height: initial;
        word-break: break-all; }
      .report .title-container #report-desc {
        font-size: 14px;
        color: #ffffff;
        word-break: break-all; }
  
  .pdf-result-browser {
    height: 7px;
    background-color: #f4f4f4;
    text-decoration: underline; }
  
  .footer-pagenumber {
    font-size: 10px;
    font-weight: 500;
    color: #002856;
    margin-left: 17px; }
  
  .result-browser-link {
    font-size: 10px;
    font-weight: 500;
    color: #0052d6;
    margin-right: 40px;
    padding-top: 8px;
    float: right; }
  
  .pdf-footer {
    height: 56px;
    display: block;
    border-top: 2px solid #2c2c2c;
    padding-left: 40px;
    font-size: 8px;
    line-height: 1.36; }
    .pdf-footer .tnp-nav-top-logo {
      width: 100px;
      height: 24px;
      margin-right: 40px;
      margin-top: 10px; }
    .pdf-footer .bold {
      color: #2c2c2c;
      font-weight: 500; }
  
  .backtocontent {
    font-size: 9px;
    font-weight: 500;
    color: #0052d6;
    margin-right: 40px;
    padding-top: 8px;
    float: right;
    text-decoration: underline; }
  
  .bold501 {
    font-weight: 501; }
  
  .padding-top-10 {
    padding-top: 10px; }
  
  .padding-top-5 {
    padding-top: 5px; }

  .gartner-brand{
    transform: scale(0.7);
    padding-top: 7px;
  }
  