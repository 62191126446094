.top-title-nested-middle-grid-container{
    height: 16px;
    margin-left:4px;
    border-left: 1px solid #212121;
    padding: 0 0 0 2px;
}

.gem-bar-item-bardata-color{
    height: 16px;
    display: inline-block;
    vertical-align: super;
    cursor: pointer;
    position: relative;
}

.col-name  {
    padding: 0 ;

}
.parent-record .col-bar  {
    padding:10px 4px;
}

.columnHead{
    padding:0 ;
    min-height: 23px;
}

.industry-filter-table .columnHead .col-title-container{
    display: flex;
}
.industry-filter-table th:last-child .col-title-container{
    float: right;
}

.industry-filter-table .bars .gem-bar-item-bardata-color .tooltiptext  ul{
    display: inline-block;
    width: 100%;
    padding: 0;
}
.industry-filter-table .bars .gem-bar-item-bardata-color.tooltiptext  li{
    list-style: none;
}
.industry-filter-table .bars .gem-bar-item-bardata-color .tooltiptext  .title{
    text-align: left;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    color: #616161;
}
.industry-filter-table .bars .gem-bar-item-bardata-color .tooltiptext  .value{
    float: right;
    font-weight: 500;
    margin-left: 10px;
    text-align: right;
}
.industry-filter-table .bars .gem-bar-item-bardata-color .tooltiptext {
    visibility: hidden;
    padding: 0 10px;
    position: absolute;
    z-index: 1000;
    bottom: 150%;
    left: 50%;
    margin-left: -118px;
    background-color: #ffffff;
    color: #616161;
    min-width: 231px;
    height: auto;
    min-height: 40px;
    box-shadow: 0 1px 3px 0 rgba(97, 97, 97, 0.28);
    border: solid 2px #d3d3d3;
    text-align: left;
    display: flex;
    vertical-align: middle;
    align-items: center;
    width: auto;
}

.industry-filter-table .bars .gem-bar-item-bardata-color .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -8px;
    border-width: 10px;
    border-style: solid;
    border-color: #ffffff transparent transparent transparent;
}

.industry-filter-table .bars .gem-bar-item-bardata-color:hover .tooltiptext {
    visibility: visible;
}

.industry-filter-table .table-responsive {
    overflow: visible;
}


.industry-filter-table .gem-error-message {
    text-align: center;
    margin-top: 102px;
}
.industry-filter-table .gem-error-message .analysisIcon{
    width: 49.4px;
    height: 49.4px;
    font-size: 49px;
}
.industry-filter-table .OtherIndustries{
    margin-top: 10px;

}
.industry-filter-table .OtherIndustries .title, .industry-filter-table .OtherIndustries .othersList {
    float: left;
}
.industry-filter-table .OtherIndustries .othersList {
    margin-left: 5px;
}
.industry-filter-table .OtherIndustries .title{
    font-weight: 500;
}