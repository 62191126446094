.download-report-container {
	min-width: 240px;
	z-index: 99;
	margin-left: 60px
}

.download-report-box {
	height: 60px;
	box-shadow: 0 1px 8px 0 rgba(33, 33, 33, 0.5);
	background-color: #ffffff;
}


.download-report-box .tn-heading4 {
	margin-top: 18px;
	margin-left: 10px;
	font-size: 15px;
	font-weight: 500;
	line-height: 20px;
	color: #212121;
	display: inline-block;
}

.download-report-box svg {
	margin-left: 5px;
	vertical-align: middle;
	display: inline-block;
}

.download-report-box .ga-close01 {
	font-size: 17px;
	margin-left: 5px;
	margin-right: 10px;
	color: #002856;
	display: inline-block;
	vertical-align: middle;
	cursor: pointer;
}

.download-report-align{
	float: right;
	margin-top: 13px;
}
