.main-bar{
    transform: rotate(-180deg);
    background-image: linear-gradient(to right, #416428, #6a9d0c 21%, #e9ae2a 55%, #d36b00 83%, #94332d);
}
.marker-text{
    font-size: 13px;
    line-height: 16px;
    margin-top:2px;
    height:16px;
    margin-bottom:20px;

}
.diverse-tooltipBox{
    width: 108px;
    height: 91px;
    background-color: #ffffff;
    color: #616161;
    padding:10px;
    text-align: center;
    border:3px solid;
    margin-bottom: -15px;
}
.diverse-text{
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    padding-bottom: 2px;
}
.diverse-value{
    font-size: 24px;
    font-weight: 600;
    line-height: 26px;
    color: #002856;
}
