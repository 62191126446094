
.ethnic-pie-container{
    height:175px;
}

.ethnic-pie-container .highcharts-container {
    width: 390px !important;
    height: 190px !important;
}

.legende-piechart-section{
    width: 215px;
    height: 30px;
    margin: 0px;
    padding: 0px;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: #616161;
    z-index: 1 !important;
}

.highcharts-container {
    margin: 0 auto;
}

.ethnic-diversity>div{
    height: 140px;
}

.highcharts-tooltip span {
    background-color: white;
    opacity: 1;
    z-index: 9999 !important;
    padding: 7px;
    border: solid 2px #d3d3d3;
    border-radius: 0px;
    font-weight: 500!important;
}