.curr-download-seperator{
    width: 2px;
    height: 36px;
    background-color: #d3d3d3;
    margin-left: 6px;
    margin-right: 10px;
}
.nav-right-section > div{
    float:left;
}
.nav-right-section .ga-download{
    width: 36px;
    height: 36px;
    padding: 11px 11px;
    color: #002856;
}
.nav-right-section .ga-download:hover {
    background-color: #002856;
    border-radius: 18px;
    color:#ffffff;
    cursor: pointer;
}
.pdf-download.open{
    border-radius: 100px;
    background-color: #002856;
    color: #fff;
}
.sticky-true.popover{
    top: 46px;
    right: 74px;
}

.__react_component_tooltip{
    left: 25px !important;
    top:  0px !important;
    position: relative;
}
.sticky-container .darktooltip + .tooltip.place-bottom{
    margin-top: 8px !important;
}

.role-title{
    margin-top: 20px;
    margin-bottom: 10px;
}