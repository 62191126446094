.circle-background, .circle-progress {
	fill: none;
}

.circle-background {
	stroke: #c0c0c0;
}

.circle-progress {
	stroke: #ff540a;
	stroke-linecap: square;
	stroke-linejoin: square;
}