.report .widget-item-upper{
    padding-bottom:20px;
    border-bottom: 2px solid #d3d3d3
}
.report .twin-widget-wrapper{
    border-bottom: 2px solid #d3d3d3
}
.report .twin-widget-wrapper .widget-item-upper{
    border-bottom: none;
}
.report .page-height .widget-item-upper:last-of-type{
    border-bottom: none;
}
.report .report-diversity-widget.twin-widget-wrapper{
    border-bottom: none;
}
.report .top-employers{
    padding-top:5px;
}
.report .top-employers .row-item,
.report .expandingContainer .row-item{
    margin-bottom:5px;
    line-height: 14px;
}
.report .widget-header-title{
    font-size: 11px;
    line-height: 15px;
    margin-bottom: 10px;
    height: auto;
}
.report .widget-header-subtitle{
    font-size: 10px;
}
.report  .keystat-container{
    height:73px;
    padding:10px;
} 
.report .keystat-container .keystat-name{
    font-size: 10px;
    line-height: 1.1;
}
.report .keystat-container .keystat-value{
    font-size: 11px;
    line-height: 2.09;
    bottom:3px;
}
.report #salary-section{
    height:10px !important;
    margin: 0px !important;
    margin-top: 48px !important;
    width:95% !important;
}
.report #salary-section > div{
    height:10px !important;
}
.report #salary-section .market_arrow_box{
    min-width: 68px;;
    height:35px;
    margin-top:24px;
}
.report #salary-section .salary-number,
.report #salary-section .label{
    font-size: 9px;
    line-height: 12px;
}
.report #salary-section .LeftSalaryToolTip{
    margin-left:-30px;
}
.report #salary-section .LeftSalaryToolTip:after{
    left:27px;
}
.report .guageOuterContainer .col-xs-1{
    display: none;
}
.report .guageContainer.col-xs-2{
    width: 20%;
}
.report .guageOuterContainer .guageContainer:not(:last-child){
    margin-right: 0px;
}
.report .skillsExpandedBlock .col-md-3{
    display: none;
}
.report .skillsExpandedBlock .col-md-6{
    width:100%;
}
.report .expandingContainer .expandBtnBlock{
    display: none;
}
.report .guageDataContainer{
    width:50px;
}
.report .guagePercent{
    font-size:16px;
}
.report .guageNumber{
    font-size:9px;
}
.report .data-table .columnHead .ga{
    display: none;
}
.report .data-table .top-title-nested-middle-grid-container,
.report .data-table .gem-bar-item-bardata-color{
    height:10px;
}
.report .data-table .gridcell{
    min-height: 26px;
    height:26px;
    line-height: 26px;
}
.report .data-table .parent-record .col-bar{
    padding: 8px 4px;
}
.report .report-diversity-widget .main-bar{
    height:10px !important;
}
.report .report-diversity-widget .grid-one-col-right .widget-item-upper{
    margin-top:13px;
}
.report .report-diversity-widget .diverse-tooltipBox{
    width:66px;
    height:60px;
    border:2px solid;
    padding:5px;
    margin-left: calc(21% - 38px) ;
}
.report .report-diversity-widget  .diverse-tooltipBox .diverse-text{
    font-size: 9px;
    line-height: 12px;;
    font-weight: 600;
    color: #4a4a4a;
}
.report .report-diversity-widget  .diverse-tooltipBox .diverse-value{
    font-size: 16px;
    line-height: 1.25;
    color: #002856
}
.report .report-diversity-widget .diverse-tooltipBox-arrow{
    margin-bottom:6px !important;
}
.report .report-diversity-widget .diverse-tooltipBox-arrow >.ga-caret-down{
    font-size: 15px !important;
    top:10px !important;
}
.report .report-diversity-widget .diverse-tooltipBox-arrow .ga-caret-down .ga-caret-down{
    font-size: 11px !important;
    left: 2px !important;
}
.report .report-diversity-widget .marker-text{
    font-size: 9px;
    line-height: 1.78;
    color: #616161;
}
.report .gender-count-graph{
    width:150px
}
.report .gender-count-graph .gender-percent{
    font-size: 16px;
    line-height: 1.25;
}
.report .gender-count-graph .gender-icon{
    width: 10px !important;
    height: 20px;
    font-size: 20px;
    line-height: 20px;
}
.report .gender-count-graph .gender-message{
    font-size: 9px;
    line-height: 1.22;
}
.report .gender-count-graph .gender-count{
    font-size: 9px;
    line-height: 1.78;
}
.report .legende-piechart-section{
    font-size: 9px;
    line-height: 1.78;
    height:20px;
}
.report .ethnic-diversity>div{
    height:100px
}
.report .coverpage-height{
    height: 667px;
}
.report .page-height {
    height: 884px;
}