.widget-item-upper{
    border-bottom:1px solid #d3d3d3;
    padding-bottom:29px;
    margin-bottom: 5px;
}
.widget-header-title{
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    color: #002856;
    margin-bottom: 15px;
    margin-top:10px;
    height:23px;
}
.widget-header-subtitle{
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    color: #4a4a4a;
}
.twin-widget-wrapper{
    display: grid;
    display: -ms-grid;
    grid-template-columns: 50% 50%;
    -ms-grid-columns:50% 50%;
    border-bottom:1px solid #d3d3d3;
    margin-bottom: 5px;
}
.twin-widget-wrapper > div:last-child{
    padding-left:10px;
}
.twin-widget-wrapper .widget-item-upper{
    border-bottom:none;
    margin-bottom: 0;
}
.grid-one-col-left {
    grid-column: 1;
    -ms-grid-column: 1;
    padding-right:10px;
}

.grid-one-col-right {
    grid-column: 2;
    -ms-grid-column: 2;
}