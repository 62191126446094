.rep-main-heading{
    font-size: 14px;
    font-weight: 500;
    line-height: 15px;
    color: #002856;
    margin-top:30px;
    margin-bottom:25px;
}
.rep-role-name{
    font-size: 12px;
    font-weight: 500;
    color: #002856;
    margin-bottom:5px;
}
.rep-location{
    font-size: 10px;
    line-height: 1.2;
    color: #212121
}
.rep-role-heading{
    padding-bottom:15px;
    border-bottom:1px solid #e0e0e0;
    margin-bottom:15px;
}
.rep-intake-heading{
    font-size: 12px;
    font-weight: 500;
    line-height: 1;
    color: #002856;
}
.rep-skills-heading{
    font-size: 9px;
    font-weight: 600;
    line-height: 1.33;
    color: #212121;
    margin-top:8px;
    margin-bottom:5px;
}
.rep-content{
    font-size: 10px;
    line-height: 1.6;
    color: #212121;
}